<template>
  <div class="container-msg"
    :class="{ 'ia-class': type === 'ia', 'user-class': type === 'user' }">
    <img v-if="type === 'ia'" class="icon-chat"
      :src="require('@/assets/svgs/icon_cascadas.svg')" alt="Icono" />
    <p :class="{ 'txt-msg-user': type === 'user', 'txt-msg-ia': type === 'ia' }">{{ msg }}</p>
    <img v-if="type === 'user'" class="icon-chat"
      :src="require('@/assets/svgs/icon_user.svg')" alt="Icono" />
  </div>
</template>

<script>
export default {
  name: 'IAMsg',
  props: {
    msg: String,
    type: String
  },
  data () {
    return {}
  }
}
</script>

<style scoped>
  .container-msg{
    margin: 5px;
    display: flex;
    color: white;
    align-items: center;
  }
  .ia-class{
    flex-direction: row;
  }
  .user-class{
    flex-direction: row-reverse;
  }
  .txt-msg-user{
    background: #CDF2F4;
    color: #297077;
    padding: 10px;
    margin-right: 5px;
    border-radius: 8px;
    text-align: right;
  }
  .txt-msg-ia{
    background: #868E96;
    color: white;
    padding: 10px;
    margin-right: 5px;
    border-radius: 8px;
    text-align: left;
  }
  .icon-chat{
    width: 30px;
    height: 30px;
    margin-right: 20px;
    background-color: white;
    border-radius: 50%;
    text-align: left;
  }
</style>

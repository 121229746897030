<template>
    <div v-if="loading" class="three-dots-preloader">
        <span class="dot" v-for="(dot, index) in 3" :key="index"></span>
    </div>
</template>

<script>
export default {
  name: 'LoadChat',
  props: {
    loading: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style>
.three-dots-preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.dot {
  width: 10px; /* Ancho de los puntos */
  height: 10px; /* Altura de los puntos */
  margin: 0 5px; /* Espacio entre los puntos */
  background-color:#F75017; /* Color de los puntos */
  border-radius: 50%; /* Forma redonda */
  animation: bounce 0.3s infinite alternate;
}

/* Animación de rebote para los puntos */
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px); /* Altura del rebote */
  }
}

.dot:nth-child(2) {
  animation-delay: 0.2s; /* Retraso para el segundo punto */
}

.dot:nth-child(3) {
  animation-delay: 0.4s; /* Retraso para el tercer punto */
}
</style>

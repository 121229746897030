<template>
  <Chat/>
</template>

<script>
import Chat from './views/Chat.vue'
export default {
  name: 'App',
  components: {
    Chat
  }
}
</script>

<style>
  #app {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    background-color: #EFF0F4;
  }
</style>

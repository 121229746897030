import { createApp } from 'vue'
import App from './App.vue'
import store from './store'

import axios from 'axios'
const app = createApp(App)

axios.defaults.headers.common.Authorization = 'Bearer ' + process.env.VUE_APP_TOKEN_OIA
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['OpenAI-Beta'] = 'assistants=v2'

app.config.globalProperties.$axios = axios

app.use(store).mount('#app')
